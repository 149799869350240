body {
  font-family: â€œHelvetica Neueâ€, Helvetica, Arial, sans-serif;
  background-color: rgba(67, 91, 18, 0.544);
  font-size: 28px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

h1{
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 75px;
  letter-spacing: -1.6px;
  word-spacing: -0.2px;
  color: #000000;
  font-weight: 700;
  text-decoration: line-through solid rgb(68, 68, 68);
  font-variant: normal;
  text-transform: lowercase;
  z-index: 20;
  margin-left: 10px;
}

h2 {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 30px;
  letter-spacing: -1.6px;
  word-spacing: -0.2px;
  color: #000000;
  font-weight: 700;
  text-decoration: line-through solid rgb(68, 68, 68);
  font-variant: normal;
  text-transform: lowercase;
  z-index: 20;
  margin-left: 10px;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.listBackground {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.marquee{
  width: min-content;
}
.hideButton {
  position: absolute;
  top: 150px;
  right: 5px;
  z-index: 20;
}
.favorite_div {
  text-align: center;
}
#best_header {  
  background-color: rgba(67, 91, 18);
  width: min-content;
}

#cv {
  color: #8346a0;
  font-size: 25px;
  font-weight: bold;
}

#pic {
  position: absolute;
  left: 15px;
  bottom: -400px;
  z-index: -1;
  opacity: .35;
}

#flexbox{
  margin: auto;
  justify-content: center;
  width: max-content;
  height: max-content;
  position: absolute;
  bottom: 10px;
}

#best {
  width: 100vw;
}

#ones_to_watch{
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: row;
  justify-content: row;
}

#today{
  background-color: rgb(136, 100, 22);
  text-align: center;
  position: absolute;
  top: 250px;
  right: 5px;
  z-index: 15;
  padding: 5px;
  width: min-content;
  height: min-content;
  font-size: 14px;
}

#artistSpotlight{
  background-color: rgb(136, 100, 22);
  position: absolute;
  top: 850px;
  left: 25px;
  z-index: 25;
  padding: 5px;
  width: min-content;
  height: min-content;
  font-size: 16px;
  text-align: center;
}

#vids{
  position: absolute;
  left: 10px;
  top: 165px;
  z-index: 10;
  width: 300px;
  height: 300px;
}

#vids2{
  position: absolute;
  right: 5px;
  top: 1275px;
  z-index: 25;
  width: 375px;
  height: 375px;
}

#sc1 {
  position: absolute;
  top: 900px;
  right: 10px;
  z-index: 3;
  width: 275px;
  height: 350px;
}

#sc2 {
  position: absolute;
  top: 475px;
  left: 15px;
  z-index: 5;
  width: 270px;
  height: 400px;
}

#tv_icon:hover {
  cursor: zoom-in;
}

#discord{
  width: 245px;
  height: 275px;
  position: absolute;
  top: 1675px;
  left: 35px;
  z-index: 20;
}

#twitter{
  position: absolute;
  top: 1665px;
  right: 25px;
  z-index: 20;
}

  @media (min-width: 1150px){
    h2{
      font-size: 40px;
    }

    #vids{
      position: fixed;
      left: 625px;
      top: 35px;
      z-index: 11;
      width: 900px;
      height: 900px;
    }

    #vids2{
      top: 1000px;
      right: 50px;
      width: 500px;
      height: 500px;
    }

    #artistSpotlight{
      bottom: 50px;
      top: unset;
      left: 525px;
      z-index: 10;
      font-size: 20px;
    }

    #today {
      top: 275px;
      right: 50px;
      font-size: 20px;
      z-index: 15;
    }

    #sc1 {
      position: absolute;
      bottom: -600px;
      left: 150px;
      width: 400px;
      height: 400px;
    }

    #sc2 {
      position: absolute;
      top: 140px;
      left: 50px;
      z-index: 20;
      width: 400px;
      height: 750px;
    }

    #twitter{
      right: unset;
      top: 1625px;
      left: 200px;
      width: 200px;
      height: 200px;
    
    }

    @media (min-width: 1800px){

      #vids{
        position: fixed;
        left: 900px;
        top: unset;
        bottom: 5px;
        z-index: 20;
        width: 1500px;
        height: 1250px;
      }
      
      #vids2{
        top: 1350px;
        width: 800px;
        height: 800px;
      }

      #artistSpotlight{
        bottom: 50px;
        top: unset;
        z-index: 10;
        font-size: 20px;
        right: unset;
        left: 775px;
        z-index: 10;
      }
  
      #today {
        top: 350px;
        right: 50px;
        font-size: 24px;
        z-index: 20;
      }
  
      #sc1 {
        position: absolute;
        top: 1100px;
        left: 200px;
        width: 400px;
        height: 400px;
      }
  
      #sc2 {
        position: absolute;
        top: 300px;
        left: 125px;
        z-index: 20;
        width: 500px;
        height: 775px;
      }

      #twitter{
        right: unset;
        top: 1625px;
        left: 200px;
        width: 200px;
        height: 200px;
      }
    
  }
}